@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Outfit:wght@100..900&display=swap');


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Manrope;
  /* overflow: hidden; */
}

#root {
  min-height: 100vh;
  width: 100%;
  display: flex;
  animation: fadeIn 1.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

video {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    z-index:-100;
    object-fit: cover;
  }

  /* Add this CSS to your styles */
.ellipsis {
  display: inline-block;
  width: 80px;
  height: 15px;
  position: relative;
}

.ellipsis div {
  position: absolute;
  top: 0;
  width: 15px;
  height: 15px;
  background: #333;
  border-radius: 50%;
  animation: ellipsis 1.2s infinite;
}

.ellipsis div:nth-child(1) {
  left: 0;
  animation-delay: -0.24s;
}

.ellipsis div:nth-child(2) {
  left: 20px;
  animation-delay: -0.12s;
}

.ellipsis div:nth-child(3) {
  left: 40px;
  animation-delay: 0;
}

@keyframes ellipsis {
  0% {
      transform: scale(0);
  }
  50% {
      transform: scale(1);
  }
  100% {
      transform: scale(0);
  }
}
