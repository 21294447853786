@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Outfit:wght@100..900&display=swap');


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Manrope;
}

#root {
  min-height: 100vh;
  width: 100%;
  display: flex;
  animation: fadeIn 1.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.home-container {
    background-image: url('./assets/backg3.png');
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 80px;
    left: 0;
    bottom: 0;
    width: 100%;
    /* z-index: -10; */
}